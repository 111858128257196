@import '../../global.scss';

.contact {
  background-color: $darkBlue;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100vh;

  @include mobile {
    flex-direction: column;
    justify-content: center;
  }

  .left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .wrapper-left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @include mobile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      .please {
        color: $grey;
        font-size: 30px;

        @include mobile {
          font-size: 20px;
        }
      }

      .thanks {
        color: $pink;
        font-size: 100px;
        padding-bottom: 20px;
        font-weight: 900;

        @include mobile {
          font-size: 50px;
          display: flex;
          justify-content: center;
        }
      }

      .social-icons {
        color: $grey;
        transform: scale(1.5);
        padding-bottom: 10px;

        @include mobile {
          transform: scale(1);
        }

        .twitter {
          width: 24px;
        }

        .i {
          color: $grey;
          padding: 8px;
          padding-left: 0px;
          list-style: none;
          text-decoration: none;
        }
      }
    }
  }

  .rigth {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .wrapper-rigth {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;

      @include mobile {
        flex-direction: column;
        align-items: center;
        height: 90%;
      }

      h1 {
        color: $pink;
        font-size: 50px;
        font-weight: 900;
        border: 1px solid;
        border-radius: 10px;
        width: 55%;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;

        @include mobile {
          font-size: 20px;
          height: 25%;
          padding-left: 50px;
          padding-right: 50px;
          margin-top: 20px;
        }
      }

      form {
        margin-top: 20px;

        h3 {
          color: $pink;
          display: flex;
          align-items: flex-start;
          font-size: 20px;

          @include mobile {
            width: 200px;
            height: 20px;
            font-size: 15px;
          }
        }

        input {
          background-color: $grey;
          width: 400px;
          height: 50px;
          border: none;
          border-bottom: 1px solid $black;
          border-radius: 10px;
          margin: 10px 0px;
          font-size: 15px;
          padding-left: 20px;
          padding-right: 20px;

          @include mobile {
            width: 200px;
            height: 30px;
          }
        }

        textarea {
          width: 400px;
          height: 100px;
          margin: 10px 0px;
          font-size: 15px;
          resize: none;
          background-color: $grey;
          border-radius: 10px;
          max-width: 100%;
          padding-left: 20px;
          padding-right: 20px;

          @include mobile {
            width: 200px;
            height: 50px;
          }
        }

        .wrapper-text {
          display: flex;
          align-items: center;

          @include mobile {
            width: 200px;
          }

          p {
            color: $grey;
            display: flex;
            justify-content: center;
            left: 250px;
            padding-left: 20px;

            @include mobile {
              width: 200px;
              height: 50px;
              padding-top: 20px;
            }
          }

          .btnform {
            display: flex;
            justify-content: center;
            background-color: $pink;
            color: $grey;
            border: none;
            border-radius: 50px;
            padding: 10px 0;
            width: 100px;
            transition: 0.2s;
            text-decoration: none;
            font-weight: 700;
            cursor: pointer;

            @include mobile {
              width: 100px;

              border-radius: 15px;
            }

            &:hover {
              background-color: $grey;
              color: $black;
            }
          }
        }
      }
    }
  }
}
