@import '../../global.scss';

.projects {
  background-color: $darkBlue;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100vh;

  .title-page {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    bottom: 280px;

    @include mobile {
      position: relative;
      bottom: 220px;
    }

    h1 {
      color: $pink;
      font-size: 50px;
      font-weight: 900;
      border: 1px solid;
      border-radius: 10px;
      width: 30%;
      padding: 10px;

      @include mobile {
        font-size: 30px;
        width: 80%;
      }
    }
  }

  .arrow {
    height: 80px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      height: 30px;
    }

    &.left {
      left: 100px;
      transform: rotateY(180deg);
    }

    &.rigth {
      right: 100px;
    }
  }

  .slider {
    margin-top: 6rem;
    height: 50%;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      height: 200px;
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        height: 200px;
        justify-content: center;
      }

      .item {
        width: 700px;
        height: 100%;
        background-color: $grey;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          margin-left: 10px;
          margin-right: 10px;
        }

        .left {
          flex: 6;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftcontainer {
            .imgcontainer {
              img {
                width: 300px;
                height: 200px;
                border-radius: 10px;
                overflow: hidden;
                align-items: center;
                box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);

                @include mobile {
                  width: 180px;
                  height: 100px;
                  border-radius: 6px;
                }
              }
            }
            .btn-link {
              background-color: transparent;
              color: $pink;
              border: none;
              font-weight: 700;
              font-size: 14px;
              cursor: pointer;

              @include mobile {
                font-weight: 700;
                font-size: 12px;
              }

              &:hover {
                color: $black;
                border-radius: 10px;
              }
            }
          }
        }

        .right {
          flex: 6;
          // height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          // gap: 20px;

          .rigthcontainer {
            width: 90%;
            height: 80%;
            display: flex;
            flex-direction: column;
            gap: 15px;

            @include mobile {
              font-size: 15px;
            }

            h2 {
              font-size: 20px;
              color: $pink;
              text-align: left;
              height: 30%;
              display: flex;
              flex-direction: column-reverse;
              padding-bottom: 10px;

              @include mobile {
                font-size: 15px;
              }
            }

            p {
              font-size: 15px;
              color: $black;
              text-align: justify;
              height: 70%;

              @include mobile {
                font-size: 10px;
              }
            }

            span {
              font-size: 14px;
              font-weight: 700;
              color: $pink;
              text-align: left;
              height: 20%;
              padding-top: 10px;
              display: flex;
              // flex-direction: column-reverse;
              gap: 20px;

              @include mobile {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
