//for to import the global css
@import '../../global.scss';

.topBar {
  width: 100%;
  height: 70px;
  background-color: $black;
  color: $pink;
  position: fixed;
  top: 0;
  z-index: 3;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left {
    display: flex;
    align-items: center;

    .logo {
      font-size: 40px;
      font-weight: 700;
      text-decoration: none;
      color: inherit;
      margin-right: 40px;
    }

    .itemContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        display: none;
      }

      a {
        display: flex;
        align-items: center;

        &:hover {
          color: $grey;
        }
      }

      .icon {
        font-size: 15px;
        margin-right: 5px;
      }

      span {
        font-size: 15px;
        font-weight: 900;
      }
    }
  }

  .right {
    display: none;

    @include mobile {
      display: block;

      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $pink;
          transform-origin: left;
          transition: all 1s ease;
        }
      }
    }

    &.active {
      .hamburger {
        span {
          &:first-child {
            background-color: $pink;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            background-color: $pink;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
