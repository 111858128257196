@import '../../global.scss';

.about {
  background-color: $darkBlue;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .block {
      border-radius: 1rem;
      border: 2px solid $pink;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      width: 85%;
      height: 60%;

      @include mobile {
        border: 5px solid $pink;
        width: 80%;
        height: 80%;
        padding: 8px;
      }
      .wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        .designer-skills,
        .developer-skills,
        .soft-skills {
          h2 {
            text-align: left;
            color: $pink;
            font-size: 20px;
            padding-bottom: 15px;

            @include mobile {
              font-size: 14px;
            }
          }

          ul {
            li {
              color: $grey;
              text-align: left;
              font-size: 15px;

              @include mobile {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .wrapper-left {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      // background-color: antiquewhite;
      // height: 60%;
      // width: 100%;

      @include mobile {
        height: 80%;
      }

      h1 {
        color: $pink;
        font-size: 50px;
        font-weight: 900;
        border: 1px solid;
        border-radius: 10px;
        width: 50%;
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        height: 18%;

        @include mobile {
          font-size: 25px;
          border-radius: 7px;
          height: 20%;
          margin-bottom: 10px;
        }
      }

      .about-me {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 50%;
        color: $grey;
        text-align: justify;
        padding: 30px 60px 30px 60px;
        height: 70%;

        @include mobile {
          font-size: 12px;
          padding-right: 30px;
          padding-left: 30px;
          padding-top: 20px;
        }
      }

      .moving-text {
        display: flex;
        margin: 22px 0 22px 0;
        overflow: hidden;
        min-height: 60px;
        align-items: center;

        @include mobile {
          font-size: 12px;
          padding-right: 30px;
          padding-left: 30px;
          height: 30%;
        }

        p {
          color: $pink;
          font-size: 14px;
          text-align: left;

          @include mobile {
            font-size: 15px;
            padding-top: 15px;
          }
        }
      }
    }
  }
}
